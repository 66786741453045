@import '../../variables';
@import '../../mixin';

.orders-actions {
  padding: 0.1rem;
  border-top: 1px solid $lightGray;
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 115px;

  button {
    font-size: 0.88dvw;
  }

  .actions-wrap {
    display: grid;
    grid-template-columns: 40% 60%;

    .add-customer {
      height: 51px;
      display: flex;
      align-items: center;
      @include button-style();

      span {
        display: grid;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
      }

      .points {
        color: $darkColor;
        font-weight: 500;
        padding-left: 3px;
      }
    }

    .add-customer-large {
      // height: 100px;
      height: 6.25dvw;
    }

    div {
      .update-action-button {
        // height: 51px;
        height: 3.2dvw;
      }

      .price-update-color-ful {
        &:disabled {
          background: #f5f5f5;
          border-color: #d9d9d9;
        }

        background-color: $blackColor;
        border-color: $blackColor;
      }
    }

    .job-card {
      height: 45px;
      display: flex;
      align-items: center;
      @include button-style();

      span {
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
      }
    }

    div {
      // margin-left: 0.5rem;
      margin-left: 0.5dvw;

      button {
        width: 100%;
        height: 45px;

        &:not(.ant-btn-primary) {
          @include button-style();
        }
      }

      &.oil-actions {
        // display: grid;
        // grid-template-columns: 1fr 1fr 1fr;
        // column-gap: 0.5rem;
        // grid-template-columns: 1fr 1fr;
      }
    }
  }

  .payment-wrap {
    display: grid;
    grid-template-columns: calc(50% - 0.2dvw) calc(50% - 0.3dvw);
    column-gap: 0.5dvw;
    margin-top: 0.5dvw;

    .payment {
      height: 51px;
    }

    .payment-large {
      // height: 101px;
      height: 6.25dvw;
    }

    .save-for-later {
      height: 51px;
      @include button-style();

      &:disabled {
        background: #f5f5f5;
        border-color: #d9d9d9;
      }
    }

    .save-for-later-large {
      // height: 150px;
      height: 9.35dvw;
      // margin-top: -50px;
      margin-top: -3.15dvw;
    }
  }
}

.orders-dialpad-modal {
  width: 350px !important;
}

.job-card-modal {
  width: 1000px !important;

  .ant-modal-footer {
    padding: 0 !important;

    button {
      width: 100%;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      border: 0;
    }
  }
}


.actions-container {
  height: 15.2dvw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 0.3dvw;

  // grid-column-gap: 10px;
  // grid-row-gap: 10px;
  button {
    height: 100%;
    width: 100%;
    font-size: .88dvw;
  }

  .price-update-color-ful {
    &:disabled {
      background: #f5f5f5;
      border-color: #d9d9d9;
    }

    background-color: $blackColor;
    border-color: $blackColor;
  }

  .add-customer,
  .save-for-later {
    background-color: rgba(224, 170, 255, .1);
  }
}

// .add-customer-action {
//   grid-area: 1 / 1 / 2 / 2;
// }

// .quick-pay-action {
//   grid-area: 2 / 1 / 3 / 2;

//   button {
//     // background-color: lightgreen;
//     // color: #5a189a;
//     // border: 1px solid #ccc;
//   }
// }

// .payment-action {
//   grid-area: 3 / 1 / 4 / 2;
// }

// .price-update-action {
//   grid-area: 1 / 2 / 2 / 3;

// }

// .save-action {
//   grid-area: 2 / 2 / 4 / 3;
// }
