@import '../../variables';

.orders-queue {
  padding: 0.4dvw;
  display: grid;
  row-gap: 0.5rem;
  overflow: auto;
  // height: calc(100% - 215px);
  position: relative;

  .cams-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding-left: 0.5dvw;
    padding-right: 0.5dvw;

    .cams-inner-container {
      display: flex;
      justify-content: center;
      align-items: end;
      gap: 0.5dvw;

      .collapse {
        background: rgba(224, 170, 255, 0.1);
        width: 50%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        .ant-collapse-header {
          border-bottom: 1px solid #ccc;
          padding: 0.3125dvw;

          img {
            marginright: 0.625dvw;
          }
        }

        .ant-collapse-content-box {
          padding: 0;
          // box-shadow: $boxShadow;
        }

        .video-container {
          height: 100px;
          position: relative;

          .video-overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            &:hover {
              background: rgba(0, 0, 0, 0.8);
              opacity: 1;
            }

            &:focus {
              background: rgba(0, 0, 0, 0.8);
              opacity: 1;
            }
          }

          .connect-controls {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 1rem;

            input {
              margin-bottom: 0.5rem;
            }
          }

          video {
            height: 94%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .web-cam-collapse {}

      .ip-cam-collapse {}
    }
  }

  .order-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .order {
    cursor: pointer;
    width: 100%;
    padding: 0.3dvw 0.5dvw;
    display: grid;
    grid-template-columns: auto 3dvw;
    align-items: center;

    &.ingredient {
      padding-left: 1.5rem !important;
      font-style: italic !important;

      div {
        font-weight: normal !important;
      }
    }

    &.is_addon {
      border: 0.5px solid rgba(90, 24, 154, 1);
      border-radius: 0.5rem;
      margin-bottom: 0.5rem;
      // color: white;
    }

    .title-and-description {
      .title {
        font-weight: 500;
        font-size: 0.9dvw;
      }

      .description {
        padding-left: 0.5rem;

        .quantity {
          font-weight: 500;
          font-size: 0.84dvw;
        }

        .anticon {
          font-size: 0.9dvw;
        }
      }

      .orderline-tax {
        padding-left: 0.5rem;
        font-size: 0.84dvw;

        .tax-text {
          font-weight: 500;
        }

        .anticon {
          font-size: 0.9dvw;
        }
      }

      .react_instructions_list {
        padding-left: 0.5rem;

        .instructions {
          color: $darkColor;
          font-weight: 500;
          font-size: 0.84dvw;
        }

        .quantity {
          font-size: 0.84dvw;
        }

        .anticon {
          font-size: 0.9dvw;
        }
      }

      .orderline-note {
        padding-left: 0.5rem;

        .note-icon {
          color: $darkColor;
          font-weight: 500;
          font-size: 0.84dvw;
        }

        .note-text {
          font-size: 0.84dvw;
        }

        .anticon {
          font-size: 0.9dvw;
        }
      }
    }

    .total {
      font-size: 1dvw;
      float: right;
      text-align: end;
    }
  }

  .selected {
    background-color: $orderBackgroundColor;
    border-radius: $borderRadius;
  }

  .is-redeemed {
    color: $yellowColor;
  }

  .drive-thru-only {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-top: 0 !important;
  }

  .total-price-wrapper {
    border-top: 2px solid $darkColor;
    padding-top: 0.5rem;
    float: right;
    margin-top: 0.5dvw;
    margin-right: 1dvw;

    div {
      font-size: 0.94dvw;
      font-weight: bold;
      display: flex;
      justify-content: space-between;

      span {
        margin-left: 1rem;
        color: $darkColor;
        font-weight: bold;
      }
    }
  }
}

.center {
  place-items: center;
}

.delete-order-line-modal {
  .delete-order-line-modal-content-wrapper {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 20% 75%;
    place-items: center;
    column-gap: 5%;

    .anticon-exclamation-circle {
      color: #d9363e;
      font-size: 2.5rem;
    }
  }

  .button-wrapper {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    button {
      height: 40px;
      width: 6rem;
    }
  }
}
