@import "../../variables";
@import "../../mixin";

.orders-dialpad {
  margin: 1rem;
  overflow: hidden;
  border-radius: $borderRadius;

  .heading {
    text-align: center;
    color: $darkColor;
  }

  .toggle-buttons {
    button {
      width: 50%;

      &:first-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .display {
    width: 100%;
    background-color: $veryLightGray;
    height: 40px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    border-radius: $borderRadius;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }

  .pad {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 1rem;

    button {
      height: 3.5rem;
      font-weight: 500;
      font-size: large;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
    }

    button:hover {
      color: #7135a6 !important;
      // background: #fff;
      border-color: #7135a6 !important;
    }

    button:last-child {
      svg {
        height: 16px;
      }
    }
  }

  .action-buttons {
    display: grid;
    grid-template-columns: 25% 25% 45%;
    column-gap: 2.5%;

    button {
      height: 45px;

      &:not(:last-child) {
        @include button-style();
      }
    }

    &.oil-actions {
      grid-template-columns: 50% 0% 45%;
    }

    &.single-btn {
      grid-template-columns: 100%;
    }
  }
}
